import React, { useEffect, useRef, useState } from "react";
import {
  ChatBotContainer,
  ChatContainer,
  CustomInput,
  InputContainer,
  StyledChatBox,
  SubmitButton,
} from "./StyledAiChatbox";

import theme from "../../../Services/Utils/themes";
import StyledInput from "../../../Components/StyledComponents/StyledInput";
import SendIcon from "../../../Components/Svgicons/SendIcon";
import ZeroState from "./ZeroState";
import ChatView from "./ChatView";
import { USERTYPE } from "../../../Services/Utils/helper";
import StyledText from "../../../Components/StyledComponents/StyledText";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AiStartIcon from "../../../Components/Svgicons/AiStartIcon";

const ChatboxAi = ({
  chatListing,
  setChatMessages,
  chatMessages,
  userType,
  lsToken,
  publicUserKey,
  handleNewChat,
  limitExceedModal,
  setLimitExceedModal,
}) => {
  const [text, setText] = useState("");
  const { t } = useTranslation();
  const textareaRef = useRef(null);
  const [waitingForAnswer, setWaitingForAnswer] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);

  const userIsAdmin = userType === USERTYPE?.ADMIN;
  const userIsClient = userType === USERTYPE?.CLIENT;
  const userIsNominee = userType === USERTYPE?.NOMINEE;

  const BASE_URL =
    userIsClient || (userIsNominee && lsToken)
      ? "api"
      : userIsAdmin && lsToken && "backoffice";

  const messageEndRef = useRef(null);

  const answerLength = chatMessages?.filter(
    (item) => item?.senderType === "AGENT" && item?.message !== undefined
  );

  const handleChange = (e) => {
    setText(e.target.value);
    adjustHeight();
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reset height
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
  };

  const resetHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }
  };

  useEffect(() => {
    if (chatListing) {
      setChatMessages(chatListing);
    }
  }, [chatListing]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && text.trim() !== "") {
      e.preventDefault();
      !waitingForAnswer && handleSendSocketMessage(text); // Send message when Enter is pressed
    }
  };

  // const handleSendSocketMessage = (example) => {
  //   if (!lsToken && answerLength?.length === 5) {
  //     setLimitExceedModal(true);
  //   } else {
  //     let questionPayload = {
  //       message: !text ? example?.question : text,
  //       senderType: "USER",
  //     };
  //     setChatMessages((prevMessages) => [...prevMessages, questionPayload]);
  //     setWaitingForAnswer(true); // Set waiting for answer flag

  //     const tokenBaseUrl = `${
  //       process.env.REACT_APP_API_URL
  //     }/${BASE_URL}/chatBot/ask-question?question=${encodeURIComponent(
  //       questionPayload.message
  //     )}&token=${encodeURIComponent(lsToken)}&newChat=${
  //       chatMessages?.length > 0 ? false : true
  //     }`;

  //     const publicUrl = `${
  //       process.env.REACT_APP_API_URL
  //     }/api/chatBot/public/ask-question?question=${encodeURIComponent(
  //       questionPayload.message
  //     )}&userId=${encodeURIComponent(publicUserKey)}`;

  //     const apiUrl = lsToken ? tokenBaseUrl : publicUrl;

  //     const eventSource = new EventSource(apiUrl);
  //     eventSource.onmessage = (event) => {
  //       if (event.data === "[DONE]") {
  //         setWaitingForAnswer(false); // End typing effect when done
  //         eventSource.close();
  //         return;
  //       }

  //       try {
  //         const chunk = event.data;
  //         setChatMessages((prevMessages) => {
  //           const lastMessage = prevMessages[prevMessages.length - 1];
  //           if (lastMessage?.messageType === "answer") {
  //             // Append the chunk to the last answer message
  //             return [
  //               ...prevMessages.slice(0, -1),
  //               { ...lastMessage, message: lastMessage.message + " " + chunk }, // Append chunk to existing answer
  //             ];
  //           } else {
  //             // If no answer message exists, create a new one with the chunk

  //             return [
  //               ...prevMessages,
  //               {
  //                 message: chunk,
  //                 senderType: chunk?.includes("error") ? "error" : "AGENT",
  //               },
  //             ];
  //           }
  //         });
  //         setWaitingForAnswer(false);
  //       } catch (error) {
  //         console.error("Error in processing SSE:", error);
  //       }
  //     };
  //     eventSource.onerror = (error) => {
  //       console.error("Error in SSE connection:", error);
  //       setWaitingForAnswer(false);
  //       eventSource.close();
  //     };

  //     setText("");
  //     resetHeight();
  //   }
  // };

  const handleSendSocketMessage = (example) => {
    if (!lsToken && answerLength?.length === 5) {
      setLimitExceedModal(true);
    } else if (lsToken && answerLength?.length === 30) {
      setLimitExceedModal(true);
    } else {
      let questionPayload = {
        message: !text ? example?.question : text,
        senderType: "USER",
      };

      setChatMessages((prevMessages) => [...prevMessages, questionPayload]);
      setWaitingForAnswer(true);
      setIsAnswer(true);

      const tokenBaseUrl = `${process.env.REACT_APP_API_URL
        }/${BASE_URL}/chatBot/ask-question?question=${encodeURIComponent(
          questionPayload.message
        )}&token=${encodeURIComponent(lsToken)}&newChat=${chatMessages?.length > 0 ? false : true
        }`;

      const publicUrl = `${process.env.REACT_APP_API_URL
        }/api/chatBot/public/ask-question?question=${encodeURIComponent(
          questionPayload.message
        )}&deviceId=${encodeURIComponent(publicUserKey)}`;

      const apiUrl = lsToken ? tokenBaseUrl : publicUrl;

      const eventSource = new EventSource(apiUrl);

      let currentMessage = "";
      let typingBuffer = "";

      // eventSource.onmessage = (event) => {
      //   if (event.data === "[DONE]") {
      //     setIsAnswer(false);
      //     eventSource.close();
      //     return;
      //   }

      //   const chunk = event.data;
      //   typingBuffer += chunk;

      //   const addBreakBeforeBold = (text) => {
      //     return text.replace(/(\d+\.\s)?(\*\*.*?\*\*)/g, "<br>$1$2");
      //   };

      //   const typeChunk = () => {
      //     if (typingBuffer.length > 0) {
      //       currentMessage += typingBuffer[0];
      //       typingBuffer = typingBuffer.slice(1);

      //       const formattedMessage = addBreakBeforeBold(currentMessage);

      //       setChatMessages((prevMessages) => {
      //         const lastMessage = prevMessages[prevMessages.length - 1];
      //         if (lastMessage?.senderType === "AGENT") {
      //           return [
      //             ...prevMessages.slice(0, -1),
      //             { ...lastMessage, message: formattedMessage },
      //           ];
      //         } else {
      //           return [
      //             ...prevMessages,
      //             { message: formattedMessage, senderType: "AGENT" },
      //           ];
      //         }
      //       });

      //       setTimeout(typeChunk, 20);
      //     } else {
      //       setWaitingForAnswer(false);
      //       setIsAnswer(false);
      //     }
      //   };

      //   if (typingBuffer.length === chunk.length) {
      //     setWaitingForAnswer(true);
      //     typeChunk();
      //   }
      // };

      eventSource.onmessage = (event) => {
        if (event.data === "[DONE]") {
          setIsAnswer(false);
          eventSource.close();
          return;
        }

        try {
          // Check if the chunk contains JSON
          const parsedData = JSON.parse(event.data);

          if (parsedData.error) {
            // Stop the loading immediately when we start processing the error message
            setWaitingForAnswer(false); // Stop loader as soon as we start processing the error
            setIsAnswer(false);
            let errorBuffer = parsedData.error;
            let errorMessage = "";

            const typeErrorChunk = () => {
              if (errorBuffer.length > 0) {
                errorMessage += errorBuffer[0];
                errorBuffer = errorBuffer.slice(1);

                setChatMessages((prevMessages) => {
                  const lastMessage = prevMessages[prevMessages.length - 1];
                  if (lastMessage?.senderType === "ERROR") {
                    return [
                      ...prevMessages.slice(0, -1),
                      { ...lastMessage, message: errorMessage },
                    ];
                  } else {
                    return [
                      ...prevMessages,
                      { message: errorMessage, senderType: "ERROR" },
                    ];
                  }
                });

                setTimeout(typeErrorChunk, 20); // Simulate typing delay
              } else {
                // Stop the loader after typing is done
                setIsAnswer(false);
              }
            };

            // Start the error message typing
            typeErrorChunk();
            eventSource.close();
            return;
          }
        } catch {
          // Continue processing non-JSON chunks
          const chunk = event.data;
          typingBuffer += chunk;

          const addBreakBeforeBold = (text) => {
            return text.replace(/(\d+\.\s)?(\*\*.*?\*\*)/g, "<br>$1$2");
          };

          const typeChunk = () => {
            if (typingBuffer.length > 0) {
              currentMessage += typingBuffer[0];
              typingBuffer = typingBuffer.slice(1);

              const formattedMessage = addBreakBeforeBold(currentMessage);

              setChatMessages((prevMessages) => {
                const lastMessage = prevMessages[prevMessages.length - 1];
                if (lastMessage?.senderType === "AGENT") {
                  return [
                    ...prevMessages.slice(0, -1),
                    { ...lastMessage, message: formattedMessage },
                  ];
                } else {
                  return [
                    ...prevMessages,
                    { message: formattedMessage, senderType: "AGENT" },
                  ];
                }
              });

              setTimeout(typeChunk, 20);
            } else {
              setWaitingForAnswer(false);
              setIsAnswer(false);
            }
          };

          if (typingBuffer.length === chunk.length) {
            setWaitingForAnswer(true);
            typeChunk();
          }
        }
      };

      eventSource.onerror = (error) => {
        setWaitingForAnswer(false);
        setIsAnswer(false);
        eventSource.close();
      };

      setText("");
      resetHeight();
    }
  };

  return (
    <ChatContainer>
      <TextContainer>
        <div>
        <AiStartIcon color={theme.primary}/>
        </div>
        <StyledText
          $fontWeight={500}
          $fontSize="12px"
          color={theme.betaColor}
        >
          Svenska AI is Beta version
        </StyledText>

      </TextContainer>
      <ChatBotContainer ispadding={chatMessages?.length}>
        <StyledChatBox>
          {chatMessages?.length > 0 ? (
            <ChatView
              message={chatMessages}
              responses={answerLength}
              show={limitExceedModal}
              messageEndRe={messageEndRef}
              waitingAnswer={waitingForAnswer}
              isAnswer={isAnswer}
              lsToken={lsToken}
              handleNewChat={handleNewChat}
            />
          ) : (
            <ZeroState handleSendSocketMessage={handleSendSocketMessage} />
          )}
        </StyledChatBox>

        <InputContainer className="mt-3">
          <CustomInput>
            <StyledInput
              className="rounded-3  align-self-center"
              $borderWidth="0"
              as={"textarea"}
              onKeyDown={handleKeyDown}
              rows={1}
              type="text"
              value={text}
              onChange={handleChange}
              ref={textareaRef}
              placeholder={t("common:askAnythingPlaceholder")}
              placeholderColor={theme.secondary}
            />

            <div>
              <SubmitButton
                onClick={handleSendSocketMessage}
                className={
                  !text || text.trim() === "" || waitingForAnswer
                    ? "feature-pending"
                    : ""
                }
              >
                <SendIcon color={theme.white} />
              </SubmitButton>
            </div>
          </CustomInput>
        </InputContainer>




        <StyledText
          $fontWeight={400}
          $fontSize="12px"
          className="mt-2 d-none d-md-block"
        >
          {t("common:alertMessageAI")}
        </StyledText>
        {/* <StyledText
          $fontWeight={600}
          $fontSize="12px"
          color={theme.blackTone}
        >
          Svenska AI is Beta version
        </StyledText> */}
      </ChatBotContainer>


    </ChatContainer>
  );
};


const TextContainer = styled.div`
position:absolute;
top:0;
left:47px;
background:${theme.lightBorder};
padding:6px 8px 6px 8px;
border-radius:5px;
margin-top:10px;
gap:5px;
display:flex;
align-items:center;

@media screen and (max-width: 768px) {
display:none;
}

`

export default ChatboxAi;

import React from "react";
import { styled } from "styled-components";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import StyledLanguageSwitch from "../../Components/StyledComponents/StyledLanguageSwitch";
import DrawerIcon from "../../Components/Svgicons/DrawerIcon";
import svenskaLogo from "../../Assets/Images/svenskaLogo.png";
import DocumentDrawerIcon from "../../Components/Svgicons/DocumentDrawerIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotificationDropDown from "../../Components/StyledComponents/NotificationDropdown";
import { useGetClientNotificationsQuery } from "../../Services/DashboardApi/dashboardApiSlice";
import StarsIcon from "../../Components/Svgicons/StarsIcon";
import StyledButton from "../../Components/StyledComponents/StyledButton";

const Header = ({ toggleSidebar, docToggleSidebar, user }) => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const initialFilters = {
    perPage: 5,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const { data: notifications, refetch: refetchNotification } = useGetClientNotificationsQuery({
    sortDir: initialFilters.sortedColumn.order,
    sort: initialFilters.sortedColumn.path
  });

  const location = useLocation();

  const handleRequestSocket = () => {
    nav('/svenska-ai');
  };



  return (
    <>
      <StyledContainer>
        <div className="d-md-none cursor-pointer" onClick={toggleSidebar}>
          <DrawerIcon />
        </div>

        <div className="d-md-none">
          <img src={svenskaLogo} alt="svenskaLogo" width={150} />
        </div>
        <div className="d-none d-md-block">

          <div className="d-flex align-items-center gap-2 ">
            <StyledText $fontWeight={400} $fontSize="14px" color={theme.secondary}>
              <b>{t('admin:welcomeBack')}</b>&nbsp;{`${user?.data?.user?.firstName || ""} ${user?.data?.user?.lastName || ""
                }`}
            </StyledText>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <div className="me-2">
            <div className='d-none d-md-block'>
              <StyledButton icon={<StarsIcon />} variant="white" onClick={handleRequestSocket}>

                <StyledText $fontWeight={500} $fontSize="13px" color={theme.secondary}>
                   {t('common:trySevenskaAi')}
                </StyledText></StyledButton>
            </div>


            <div className='d-block d-md-none'>
              <StyledButton variant="white" onClick={handleRequestSocket}>
                <StarsIcon />
              </StyledButton>
            </div>
          </div>
          <NotificationDropDown toggleClassName="px-0 bg-white me-2 me-md-3" notifications={notifications} refetch={refetchNotification} />
          <StyledLanguageSwitch />
          <SharedDocDrawer onClick={docToggleSidebar} route={location?.pathname.includes('dashboard')} className={`cursor-pointer`}>
            <DocumentDrawerIcon />
          </SharedDocDrawer>
        </div>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 height: 52px;
`;


const SharedDocDrawer = styled.div`
display:none;
@media (max-width: 1183px){
display:${({ route }) => route ? 'block' : 'none'};
}

@media (max-width: 768px){
display:none;
}
`;

export default Header;

import React from "react";
import { Button, HeaderContainer, HeaderTopContainer } from "./StyledAiHeader";
import LogoIcon from "../../../Components/Svgicons/LogoIcon";
import StyledText from "../../../Components/StyledComponents/StyledText";
import StarsIcon from "../../../Components/Svgicons/StarsIcon";
import theme from "../../../Services/Utils/themes";
import StyledImage from "../../../Components/StyledComponents/StyledImage";
import {
  DefaulImage,
  handleErrorImg,
  USERTYPE,
} from "../../../Services/Utils/helper";
import EditIcon from "../../../Components/Svgicons/EditIcon";
import { useNavigate } from "react-router-dom";
import {
  useGetUserProfileQuery,
} from "../../../Services/DashboardApi/dashboardApiSlice";
import { useGetAdminProfileQuery } from "../../../Services/Admin Api/adminDashboardApi/adminProfileApiSlice";
import StyledLanguageSwitch from "../../../Components/StyledComponents/StyledLanguageSwitch";
import svenskaLogo from "../../../Assets/Images/svenskaLogo.png";
import styled from "styled-components";
import AiStartIcon from "../../../Components/Svgicons/AiStartIcon";

const HeaderAi = ({ userType, lsToken, handleNewChat }) => {


  const { data: getAdminProfile } = useGetAdminProfileQuery(undefined, {
    skip: userType !== USERTYPE.ADMIN, // Only fetch if the user is an admin
  });

  const { data: getUserDetail } = useGetUserProfileQuery(undefined, {
    skip: userType !== USERTYPE.CLIENT, // Only fetch if the user is a client
  });

  const userIsAdmin = userType === USERTYPE?.ADMIN;
  const userIsClient = userType === USERTYPE?.CLIENT;

  let profile = userIsAdmin
    ? getAdminProfile?.data?.signedUrl
    : userIsClient
      ? getUserDetail?.data?.user?.signedUrl
      : DefaulImage?.image;

  const nav = useNavigate();

  const handleClick = () => {
    if (userIsAdmin) {
      nav("/user-workplace");
    } else if (userIsClient) {
      nav("/dashboard");
    }
  };

  return (
    <HeaderTopContainer>
      <HeaderContainer>
     
     <div onClick={handleClick} className="cursor-pointer">
       <img src={svenskaLogo} alt="svenskaLogo" height={40} />
     </div>
     


     <div className="d-flex align-items-center gap-3 gap-md-4">
       <div>
         <StyledLanguageSwitch />
       </div>
       {lsToken ? (
         <div className="d-none d-md-block">
           <Button onClick={handleNewChat}>
             <StarsIcon />
             <StyledText
               $fontWeight={500}
               $fontSize="13px"
               color={theme.secondary}
             >
               New Chat
             </StyledText>
           </Button>
         </div>
       ) : null}

       {lsToken ? <div className="d-block d-md-none">
         <div onClick={handleNewChat}>
           <EditIcon color={theme.secondary} size={24} />
         </div>
       </div> : null}

       {lsToken ? <StyledImage>
         <img src={profile} onError={handleErrorImg} alt="avatar" />
       </StyledImage> : null}
     </div>

     
   </HeaderContainer>
   <TextContainer className="mb-1">
        <div>
        <AiStartIcon color={theme.primary} size={15}/>
        </div>
        <StyledText
          $fontWeight={500}
          $fontSize="12px"
          color={theme.betaColor}
        >
          Svenska AI is Beta version
        </StyledText>

      </TextContainer>
    </HeaderTopContainer>
  );
};


const TextContainer = styled.div`

display:none;

@media screen and (max-width: 768px) {
background:${theme.lightBorder};
padding:0px 6px 0px 6px;
border-radius:5px;
gap:5px;
display:flex;
align-items:center;
box-shadow: 0px 4px 40px 0px rgb(53, 41, 45,0.1);
text-align:center;
}

`


export default HeaderAi;
